import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import { Link } from "react-router-dom";
import { getAllReflections } from '../../_services/reflection';
import { getMyReflections } from '../../_services/user-reflection';
import { getAllSurveysInProgress } from '../../_services/survey-in-progress';
import ReflectionCard from './reflection-card';
import CreateReflection from './create-reflection';
import Loader from '../../components/loader';

export default function ReflectionActivities(props: any) {

	const [reflections, setReflections] = useState<any>();
	const [userReflections, setUserReflections] = useState<any>();
	const [elements, setElements] = useState<any>();
	const [bonusElements, setBonusElements] = useState<any>();
	const [mySurveys, setMySurveys] = useState<any>();

	let user = JSON.parse(localStorage.getItem('user')!);
	let isAdmin = user.is_admin;

	useEffect(() => {
		getReflections();
		getMySurveys();
	}, []);

	useEffect(() => {
		if (reflections) {
			getUserReflections();
		}
	}, [reflections]);

	useEffect(() => {
		if (userReflections && mySurveys) {
			buildResults();
		}
	}, [userReflections, mySurveys]);


	const getMySurveys = () => {
		getAllSurveysInProgress().then(resp => {
			//console.log(resp.data.data);
			setMySurveys(resp.data.data);
		}).catch(err => {
			console.log(err);
		});
	}
	const getReflections = () => {
		getAllReflections().then(resp => {
			//console.log(resp.data.data);
			setReflections(resp.data.data);
		}).catch(err => {
			console.log(err);
		});
	}

	const getUserReflections = () => {
		getMyReflections().then(resp => {
			let arr = [];
			for (let i = 0; i < resp.data.data.length; i++) {
				arr.push(resp.data.data[i].reflection_id);
			}
			setUserReflections(arr);
		}).catch(err => {
			console.log(err);
		});
	}

	const buildResults = () => {
		let el = reflections.filter((item: any) => item.strategy)
			.map(function (item: any, index: number) {
				let complete = false;
				if (userReflections.indexOf(item.id) !== -1) {
					complete = true;
				}
				return <ReflectionCard key={index} reflection={item} complete={complete} mySurveys={mySurveys} handleReload={handleReload} />

			});
		setElements(el);

		let bonus = reflections.filter((item: any) => !item.strategy)
			.map(function (item: any, index: number) {
				let complete = false;
				if (userReflections.indexOf(item.id) !== -1) {
					complete = true;
				}
				return <ReflectionCard key={index} reflection={item} complete={complete} mySurveys={mySurveys} handleReload={handleReload} />
			});
		setBonusElements(bonus);
	}

	const handleReload = () => {
		setElements('');
		getReflections();
	}


	return (
		<div className="reflection-activities">
			<h1 className="mb-4 d-flex align-items-center justify-content-between">
				<span>Reflections</span>
				{isAdmin ? <CreateReflection handleReload={handleReload} /> : <div></div>}
			</h1>

			{bonusElements ?
				<Row>
					{bonusElements}
				</Row>
				:
				<Row>
					<Placeholder animation="glow">
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
					</Placeholder>
				</Row>
			}

			{elements ?
				<Row>
					{elements}
				</Row>
				:
				<Row>
					<Placeholder animation="glow">
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
					</Placeholder>
				</Row>
			}

		</div>
	)
}