import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '../../images/maximize-logo.svg';
import { ReactComponent as CaretIcon } from '../../images/caret.svg';

import LogoutButton from "../auth/logout-button";

export default function MainNav(props: any) {

	const [showRemindersDropdown, setShowRemindersDropdown] = useState<boolean>(false);
	const [showAccountDropdown, setShowAccountDropdown] = useState<boolean>(false);

	let location = useLocation();
	let userJSON = localStorage.getItem('user');
	let user = userJSON !== null ? JSON.parse(userJSON) : {};
	let isAdmin = user !== null ? user.is_admin : false;
	let isCoach = user !== null ? user.is_coach : false;

	useEffect(() => {
		document.getElementById('main-nav')?.classList.add('closed');
	}, [location.pathname]);
	
	const hideAllDropdowns = () => {
		setShowRemindersDropdown(false);
		setShowAccountDropdown(false);
	}

	const handleCloseNav = () => {
		document.getElementById('main-nav')?.classList.add('closed');
	}

	const handleOpenNav = () => {
		document.getElementById('main-nav')?.classList.remove('closed');
	}

	return (
		<div>
			<div id="mobile-header">
				<div className="open-nav" onClick={handleOpenNav}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
						<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
					</svg>
				</div>
				<Logo id="logo" />
			</div>
			<div id="main-nav" className="d-flex closed">
				<div className="close-nav" onClick={handleCloseNav}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
						<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
					</svg>
				</div>

				<div id="logo-container">
					<Logo id="logo" />
				</div>
				<div className="nav-items">
					<Link to="/workflow-routing" className={'btn btn-default ' + ( location.pathname.includes('strategy') && 'active ' )}>My Next Step</Link>
					<Link to="/strategies" className={'btn btn-default ' + ( location.pathname.includes('strategies') && 'active ' )}>Strategies</Link>
					{(isAdmin) ? <Link to="/reflections" className={'btn btn-default ' + ( location.pathname.includes('reflections') && 'active ' )}>Reflections</Link> : ''}
					<Link to="/my-profile" className={'btn btn-default ' + ( location.pathname.includes('profile') && 'active ' )}>Profile</Link>
					{(isAdmin || isCoach) ? <Link to="/users" className={'btn btn-default ' + ( location.pathname.includes('users') && 'active ' )}>Users</Link> : ''}
					{(isAdmin) ? <Link to="/reports" className={'btn btn-default ' + ( location.pathname.includes('reports') && 'active ' )}>Reports</Link> : ''}
					<Link to="/settings" className={'btn btn-default ' + ( location.pathname.includes('settings') && 'active ' )}>Settings</Link>
					<Link to="/logout" className="btn btn-default">Log Out</Link>
				</div>
			</div>
			{(showRemindersDropdown || showAccountDropdown) &&
				<div id="dropdown-backdrop" onClick={hideAllDropdowns}></div>
			}
		</div>
	)
}