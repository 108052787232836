import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getMyUserWorkflows, updateUserWorkflow, getMyActiveUserWorkflow } from "../../_services/user-workflow";
import { updateGoal } from '../../_services/goal';

export function resetCurrentWorkflow() {
	getMyActiveUserWorkflow().then((resp: any) => {
		let activeWorkflow = resp.data.data;
		let request = {
			current_step: 1,
			current_step_status: 'untouched',
			is_active: true,
			is_complete: false
		};
		updateUserWorkflow(activeWorkflow.id, request).then((resp: any) => {
			window.location.href = '/workflow-routing';
		}).catch((err: any) => {
			console.log(err);
		});
	}).catch((err: any) => {
		console.log(err);
	});
}

// request object
// 	- workflowName
// 	- stepNumber
// 	- strategyId

export function handleStepCompletion(request: any) {

	getMyActiveUserWorkflow().then((resp: any) => {
		console.log('activeWorkflow', resp.data.data);
		let activeWorkflow = resp.data.data;
		if (activeWorkflow.workflow.name === request.workflowName) {
			if (activeWorkflow.workflow.name === 'Onboarding') {
				if (activeWorkflow.current_step === request.stepNumber) {
					updateWorkflow(activeWorkflow);
				} else {
					window.location.href = '/workflow-routing';
				}
			} else {
				if (request.strategyId) {
					updateWorkflow(activeWorkflow, request.strategyId);
				} else {
					updateWorkflow(activeWorkflow);
				}
			}
		} else {
			window.location.href = '/workflow-routing';
		}

	}).catch((err: any) => {
		console.log(err);
	});

}

function updateWorkflow(item: any, strategyId?: number) {
	console.log('active workflow', item);
	// onboarding workflow
	let request = {};

	if (item.workflow.name === 'Onboarding') {
		if (item.current_step === 1) {
			request = {
				current_step: 2,
				current_step_status: 'untouched',
				is_active: true,
				is_complete: false
			};
		}
		if (item.current_step === 2) {
			request = {
				current_step: 2,
				current_step_status: 'complete',
				is_active: false,
				is_complete: true
			};
		}
	}

	if (item.workflow.name === 'Main') {
		if (item.current_step === 1) {
			request = {
				current_step: 2,
				current_step_status: 'untouched',
				is_active: true,
				is_complete: false
			};
		}
		if (item.current_step === 2) {
			request = {
				current_step: 2,
				current_step_status: 'complete',
				is_active: false,
				is_complete: true,
				strategy_id: strategyId
			};
		}
	}

	if (item.workflow.name === 'Strategy') {
		if (item.current_step < item.workflow.steps.length) {
			request = {
				current_step: item.current_step + 1,
				current_step_status: 'untouched',
				is_active: true,
				is_complete: false,
				strategy_id: strategyId
			};
		}
		if (item.current_step === item.workflow.steps.length) {
			if (item.current_step_status === 'untouched') {
				request = {
					current_step: item.workflow.steps.length,
					current_step_status: 'complete',
					is_active: true,
					is_complete: false,
					strategy_id: strategyId
				};
			} else {
				request = {
					current_step: item.workflow.steps.length,
					current_step_status: 'complete',
					is_active: false,
					is_complete: true,
					strategy_id: strategyId
				};
			}
		}
	}

	updateUserWorkflow(item.id, request).then((resp: any) => {
		window.location.href = '/workflow-routing';
	}).catch((err: any) => {
		console.log(err);
	});
}

