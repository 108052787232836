import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Placeholder from 'react-bootstrap/Placeholder';

import { getStrategies } from '../../_services/strategy';
import { getStrategyScores } from '../../_services/strategy-score';
import { getMyUserWorkflows } from '../../_services/user-workflow';
import StrategyCard from './strategy-card';

export default function StrategyList(props: any) {

	const [strategies, setStrategies] = useState<any>();
	const [strategyScores, setStrategyScores] = useState<any>();
	const [userWorkflows, setUserWorkflows] = useState<any>();
	const [elements, setElements] = useState<any>();

	useEffect(() => {
		getStrategies().then((resp: any) => {
			//console.log('strategies', resp.data.data);
			setStrategies(resp.data.data);
		}).catch(err => {
			console.log(err);
		});
		getStrategyScores().then((resp: any) => {
			//console.log('scores', resp.data.data);
			setStrategyScores(resp.data.data);
		}).catch(err => {
			console.log(err);
		});
		getMyUserWorkflows().then((resp: any) => {
			//console.log('workflows', resp.data.data);
			setUserWorkflows(resp.data.data);
		}).catch(err => {
			console.log(err);
		});
	}, []);

	useEffect(() => {
		if (strategies && strategyScores && userWorkflows) {
			buildElements();
		}
	}, [strategies, strategyScores, userWorkflows]);

	const buildElements = () => {
		let elements = strategies.map((strategy: any) => {
			let score = null;
			if (strategyScores.length > 0) {
				score = strategyScores[0].scores.find((score: any) => score.strategy_id === strategy.id);
			}
			let userWorkflowsForStrategy = userWorkflows.filter((workflow: any) => workflow.strategy?.strategy.id === strategy.id);
			let activeWorkflow = userWorkflowsForStrategy.find((workflow: any) => workflow.is_active);
			//console.log('workflows', userWorkflowsForStrategy);
			// Sort workflows by date and select the most recent one
			let mostRecentWorkflow = userWorkflowsForStrategy.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];

			return {
				key: strategy.id,
				strategy: strategy,
				score: score,
				activeWorkflow: activeWorkflow,
				mostRecentWorkflow: mostRecentWorkflow,
				element: <StrategyCard key={strategy.id} strategy={strategy} score={score} activeWorkflow={activeWorkflow} mostRecentWorkflow={mostRecentWorkflow} />
			}
		});

		// Remove null values
		elements = elements.filter(Boolean);
		elements = elements.filter((element: any) => element !== null);

		const sortedElements = elements.sort((a: any, b: any) => {
			if (a.score && b.score) {
				return a.score.score - b.score.score
			}
		});

		// Extract the element property from each item in the elements array
		elements = sortedElements.map((item: any) => item.element);

		setElements(elements);
	}

	return (
		<div className="reflection-activities">
			<h1 className="mb-4 d-flex align-items-center justify-content-between">
				<span>Choose a Strategy</span>
				<button className="btn btn-primary" onClick={() => window.location.href = '/self-assessment'}>Self-assessment</button>
			</h1>
			<p className="mb-4">Below are the 10 Maximize strategies, categorized based on the responses on your last self-assessment activity. We recommend focusing on strategies that are labeled as Area for Growth or Developing. You can come back to this page to switch between strategies at any time.</p>
			{elements ?
				<Row>
					{elements}
				</Row>
				:
				<Row>
					<Placeholder animation="glow">
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
					</Placeholder>
				</Row>
			}
		</div>
	)
}