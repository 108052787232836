import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Link} from "react-router-dom";
import ReflectionForm from './reflection-form';
import { deleteReflection } from '../../_services/reflection';

export default function DeleteReflection( props: any ) {

	const [ showModal, setShowModal ] = useState<boolean>(false);

	const handleModalOpen = () => {
		setShowModal(true);
	}

	const handleModalClose = () => {
		setShowModal(false);
	}

	const handleReload = () => {
		props.handleReload(true);
	}

	const deleteSelectedReflection = () => {
		deleteReflection( props.reflection.id ).then( resp => {
			toast('Reflection deleted successfully');
			props.handleReload(true);
			setShowModal(false);
		}).catch( err => {
			toast('There was an error');
			console.log(err);
		});
	}

	return (
		<>
			<Button variant="danger" className="ms-2" onClick={handleModalOpen}>Delete</Button>
			<Modal show={showModal} onHide={handleModalClose}>
		        <Modal.Header closeButton>
		          <Modal.Title>Delete reflection</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<p>Are you sure you would like to delete this reflection? This action is permanent.</p>
		        	<div className="d-flex justify-content-between">
			        	<Button variant="secondary" className="me-2" onClick={handleModalClose}>Nevermind</Button>
			        	<Button variant="danger" onClick={deleteSelectedReflection}>Yes, delete it</Button>
		        	</div>
		        </Modal.Body>
		      </Modal>
		</>
	)
}