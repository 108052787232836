import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Button from 'react-bootstrap/Button'
import { set } from 'date-fns';
import EditStrategy from './edit-strategy';

import { getMyActiveUserWorkflow, updateUserWorkflow, createUserWorkflow } from "../../_services/user-workflow";

import { ReactComponent as CheckIcon } from '../../images/icons/check.svg';
import { is } from 'date-fns/locale';


export default function StrategyCard(props: any) {

    const [scoreLabel, setScoreLabel] = useState<string>('');
    const [badgeClass, setBadgeClass] = useState<string>('');

    let user = JSON.parse(localStorage.getItem('user')!);
    let isAdmin = user.is_admin;

    useEffect(() => {
        if (props.score) {
            switch (props.score.score) {
                case 0:
                    setScoreLabel('Area for Growth');
                    setBadgeClass('danger');
                    break;
                case 1:
                    setScoreLabel('Developing');
                    setBadgeClass('warning');
                    break;
                case 2:
                    setScoreLabel('Skilled');
                    setBadgeClass('primary');
                    break;
                case 3:
                    setScoreLabel('Accomplished');
                    setBadgeClass('success');
                    break;
                default:
                    setScoreLabel('Not Rated');
                    break;
            }
        }
    }, [props.score]);

    const handleStart = () => {
        // get current active workflow
        // set current workflow to inactive
        // start new workflow with this strategy

        getMyActiveUserWorkflow().then((resp: any) => {
            let workflow = resp.data.data;
            if (workflow) {
                let request = {
                    current_step: workflow.current_step,
                    current_step_status: 'untouched',
                    is_active: false,
                    is_complete: false
                }
                updateUserWorkflow(workflow.id, request).then((resp: any) => {
                    //console.log('Workflow set to inactive', resp);
                    startNewWorkflow();
                }).catch((err: any) => {
                    console.log(err);
                });
            } else {
                startNewWorkflow();
            }
        }).catch((err: any) => {
            console.log(err);
        });

    }

    const startNewWorkflow = () => {
        let request = {
            workflow_id: 3,
            current_step: 1,
            current_step_status: 'untouched',
            is_active: true,
            is_complete: false,
            strategy_id: props.strategy.id
        };

        createUserWorkflow(request).then((resp: any) => {
            //console.log('New workflow started', resp);
            window.location.href = '/workflow-routing';
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const handleContinue = () => {
        // mark current active workflow as inactive
        // mark this workflow as active
        getMyActiveUserWorkflow().then((resp: any) => {
            let workflow = resp.data.data;
            let request = {
                current_step: workflow.current_step,
                current_step_status: 'untouched',
                is_active: false,
                is_complete: false
            }
            updateUserWorkflow(workflow.id, request).then((resp: any) => {
                //console.log('Workflow set to inactive', resp);
                let newRequest = {
                    current_step: props.mostRecentWorkflow.current_step,
                    current_step_status: 'untouched',
                    is_active: true,
                    is_complete: false
                }
                updateUserWorkflow(props.mostRecentWorkflow.id, newRequest).then((resp: any) => {
                    //console.log('Workflow set to active', resp);
                    window.location.href = 'workflow-routing';
                });
            });
        });
    }


    return (
        <Col xs={12}>
            <div key={props.strategy.id} className={'reflection-card mb-4 ' + ((props.activeWorkflow && props.mostRecentWorkflow.is_complete) && ' complete') + ((props.activeWorkflow && props.activeWorkflow.is_active) && ' active')}>
                <Card>
                    <Card.Body>
                        <h3>{props.strategy.name}</h3>
                        {props.score ? <p className="rating"><Badge bg={badgeClass}>{scoreLabel}</Badge></p> : <></>}

                        {
                            props.activeWorkflow ?
                                <>
                                    <div className="d-flex w-100 align-items-center progress-container">
                                        <div className="w-100 me-2">
                                            <ProgressBar now={props.activeWorkflow.current_step / props.activeWorkflow.workflow.steps.length * 100} />
                                        </div>
                                        <span className="w-25">{props.activeWorkflow.current_step}/{props.activeWorkflow.workflow.steps.length} steps</span>
                                    </div>
                                    <div className="d-flex w-100 align-items-center mt-4">
                                        <Link className="btn btn-primary me-2" to={'/workflow-routing'}>Continue</Link>
                                        <Link className="btn btn-secondary" to={'https://learn.maximizeproject.app/' + props.strategy.page_id} target="_blank">Learn more</Link>
                                        {isAdmin ?
                                            <EditStrategy strategy={props.strategy} />
                                            :
                                            <></>
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    {props.mostRecentWorkflow ?
                                        <>
                                            {props.mostRecentWorkflow.is_complete ?
                                                <>
                                                    <div className="d-flex w-100 align-items-center progress-complete mb-4">
                                                        <CheckIcon className="me-2" /> Complete
                                                    </div>
                                                    <div className="d-flex w-100 align-items-center">
                                                        <Button className="btn btn-primary me-2" onClick={handleStart}>Start focusing on this</Button>
                                                        <Link className="btn btn-secondary" to={'https://learn.maximizeproject.app/' + props.strategy.page_id} target="_blank">Learn more</Link>
                                                        {isAdmin ?
                                                            <EditStrategy strategy={props.strategy} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="d-flex w-100 align-items-center progress-container">
                                                        <div className="w-100 me-2">
                                                            <ProgressBar now={props.mostRecentWorkflow.current_step / props.mostRecentWorkflow.workflow.steps.length * 100} />
                                                        </div>
                                                        <span className="w-25">{props.mostRecentWorkflow.current_step}/{props.mostRecentWorkflow.workflow.steps.length} steps</span>
                                                    </div>
                                                    <div className="d-flex w-100 align-items-center mt-4">
                                                        <Button className="btn btn-primary me-2" onClick={handleContinue}>Start focusing on this</Button>
                                                        <Link className="btn btn-secondary" to={'https://learn.maximizeproject.app/' + props.strategy.page_id} target="_blank">Learn more</Link>
                                                        {isAdmin ?
                                                            <EditStrategy strategy={props.strategy} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="d-flex w-100 align-items-center progress-container">
                                                <div className="w-100 me-2">
                                                    <ProgressBar now={0} />
                                                </div>
                                                <span className="w-25">0/7 steps</span>
                                            </div>
                                            <div className="d-flex w-100 align-items-center mt-4">
                                                <Button className="btn btn-primary me-2" onClick={handleStart}>Start focusing on this</Button>
                                                <Link className="btn btn-secondary" to={'https://learn.maximizeproject.app/' + props.strategy.page_id} target="_blank">Learn more</Link>
                                                {isAdmin ?
                                                    <EditStrategy strategy={props.strategy} />
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </>
                                    }

                                </>

                        }

                    </Card.Body>
                </Card>
            </div>
        </Col>
    )
}