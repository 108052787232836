import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function StrategyCard(props: any) {

	useEffect(() => {
		//console.log(props);
	}, []);

	return (
		<div className="strategy-card panel">
			<Card>
				<Card.Body>
					<Row>
						<Col xs={12}>
							<h3>{props.strategy.name}</h3>
						</Col>
						{/* <Col xs={12}>
							{props.lastScore !== null && props.currentScore > props.lastScore &&
								<Badge bg="success">Showing improvement</Badge>
							}
							{props.lastScore !== null && props.currentScore < props.lastScore &&
								<Badge bg="secondary">Showing decline</Badge>
							}
						</Col> */}
					</Row>
					<div>

					{ props.lastScore !== null && props.currentScore > props.lastScore &&
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip id={`tooltip-top`}>
										Showing improvement
									</Tooltip>
								}
								>
								<div className="up-arrow">
									<div className="icon-container">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-up-short" viewBox="0 0 16 16">
											<path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/>
										</svg>
									</div>
								</div>
							</OverlayTrigger>
						}

						{/* 
						{ props.lastScore !== null && props.currentScore < props.lastScore &&
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip id={`tooltip-top`}>
										Showing decrease
									</Tooltip>
								}
								>
								<div className="down-arrow">
									<div className="icon-container">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
											<path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
										</svg>
									</div>
								</div>
							</OverlayTrigger>
						} */}
						{/* {
							props.strategy.user_goals.length > 0 &&
								<OverlayTrigger
									placement="top"
									overlay={
										<Tooltip id={`tooltip-top`}>
											Active goal
										</Tooltip>
									}
									>
									<div className="star">
										<div className="icon-container">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
												<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
											</svg>
										</div>
									</div>
								</OverlayTrigger>
							
						} */}

						<a href={"https://learn.maximizeproject.app/strategies" + props.strategy.page_id} target="_blank">Learn more</a>
					</div>
				</Card.Body>
			</Card>
		</div>
	)
}