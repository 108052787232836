import { get, post, put, destroy } from '../xhr';

export function createUser(request:any) {
	let url = 'accounts';
	return post(url, request);
}

export function updateUser(userId:number, request:any) {
	let url = 'accounts/' + userId;
	return put(url, request);
}

export function getUsers(page?: number, filters?: any) {
	let url = 'accounts?page=' + (page ? page : 1);
	if (filters) {
		var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
		url = url + '&' + queryString;
	}
	return get(url);
}

export function getUser(id: number) {
	let url = 'accounts/' + id;
	return get(url);
}

export function deleteUser(userId:number) {
	let url = 'accounts/' + userId;
	return destroy(url);
}

export function agreeToTerms() {
	let url = 'accounts/agree-to-terms';
	return get(url);
}

export function updateMe(request: any) {
	let url = 'accounts/update-me';
	return post(url, request);
}

export function resetUser(userId: number) {
	let url = 'accounts/reset/' + userId;
	return post(url, {});
}