import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import { toast } from 'react-toastify';

import { updateMe } from '../_services/user';

import { getTerms, getMyConsents, saveMyConsent } from '../_services/user-consent';
import TermsSection from '../components/user-consent/terms-section';



export default function UserSettingsPage(props: any) {

	document.title = 'User Settings | Maximize Project';

	const [newPassword, setNewPassword] = useState<string>('');
	const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');
	const [user, setUser] = useState<any>();
	const [terms, setTerms] = useState<any>('');
	const [myConsents, setMyConsents] = useState<any>('');
	const [elements, setElements] = useState<any>();
	const [isReady, setIsReady] = useState<boolean>(false);

	useEffect(() => {
		getCurrentUser();
		gatherTermsData();
	}, []);

	useEffect(() => {
		localStorage.setItem('user', JSON.stringify(user));
	}, [user]);

	useEffect(() => {
		if (terms.length > 0) {
			buildPage();
		}
	}, [myConsents]);

	const getCurrentUser = () => {
		let userJSON = JSON.parse(localStorage.getItem('user')!);
		setUser(userJSON);
	}

	const gatherTermsData = () => {
		getTerms().then(resp => {
			setTerms(resp.data.data);
			getMyConsents().then(resp => {
				setMyConsents(resp.data.data);
				setUser((prevState: any) => {
					return {
						...prevState,
						agreed_to_terms: resp.data.data[0] ? resp.data.data[0].status : 0,
						consent_to_observe_mtm: resp.data.data[1] ? resp.data.data[1].status : 0,
						consent_to_observe_research: resp.data.data[2] ? resp.data.data[2].status : 0,
					}
				})
			}).catch(err => {
				console.log(err);
			});
		}).catch(err => {
			console.log(err);
		});
	}

	const buildPage = () => {
		let el = terms.map(function (item: any, index: number) {
			if (index !== 0) {
				return <TermsSection key={index} item={item} status={myConsents[index]?.status} reload={handleReload} />
			}
		});
		setIsReady(true);
		setElements(el);
	}

	const handleReload = (event: any) => {
		gatherTermsData();
	}

	const handlePasswordInputChange = (event: any) => {
		if (event.target.id === 'new-password') {
			setNewPassword(event.target.value);
		} else {
			setNewPasswordConfirm(event.target.value);
		}
	}

	const handlePasswordSubmit = (event: any) => {
		event.preventDefault();
		if (newPassword !== newPasswordConfirm) {
			toast.error('Passwords do not match.');
			return;
		}
		let request = {
			password: newPassword
		}
		updateMe(request).then(resp => {
			setNewPassword('');
			setNewPasswordConfirm('');
			toast.success('Password changed successfully!');
		}).catch(err => {
			console.log(err);
			toast.error('There was an error saving your password.');
		});
	}

	return (
		<div id="settings-page">
			<Row className="justify-content-center">
				<Col xs={12} lg={8}>
					<h1>Settings</h1>

					<h3>Reset password</h3>
					<Form id="password-form" onSubmit={handlePasswordSubmit} className="">
						<Form.Group className="form-group">
							<Form.Label>Type your new password below.</Form.Label>
							<Form.Control id="new-password" type="text" onChange={handlePasswordInputChange} value={newPassword} />
							<div className="password-rules mt-2 mb-4">
								<span className="rule">
									{
										newPassword && newPassword.length > 7 ?
											<div className="success d-flex align-items-center">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
													<path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
												</svg>
												<span>Must be at least 8 characters</span>
											</div>
											:
											<div className="rule-text d-flex align-items-center">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
													<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
												</svg>
												<span className="rule-text">Must be at least 8 characters</span>
											</div>
									}
								</span>
							</div>
						</Form.Group>
						<Form.Group className="form-group">
							<Form.Label>Confirm your new password.</Form.Label>
							<Form.Control id="new-password-confirm" type="text" onChange={handlePasswordInputChange} value={newPasswordConfirm} />
						</Form.Group>
						<Button type="submit" className="my-4" disabled={newPassword.length < 8 || newPasswordConfirm.length < 8}>Save password</Button>
					</Form>


				</Col>
			</Row>
		</div>
	)
}
